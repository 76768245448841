var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticStyle: { "margin-top": "5px" } }, [
        _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "20px",
              position: "relative",
              "z-index": "1",
              display: "inline-block",
            },
          },
          [
            _c("el-date-picker", {
              staticClass: "input-common",
              staticStyle: { width: "115px" },
              attrs: {
                clearable: false,
                type: "month",
                "value-format": "yyyy-MM-dd",
                format: "MM-yyyy",
              },
              on: {
                change: function ($event) {
                  return _vm.searchKidsPackageByProperties()
                },
              },
              model: {
                value: _vm.dataSearch.date,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "date", $$v)
                },
                expression: "dataSearch.date",
              },
            }),
            _c(
              "el-select",
              {
                staticClass: "input-common",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "Trạng thái" },
                on: {
                  change: function ($event) {
                    return _vm.searchKidsPackageByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "status", $$v)
                  },
                  expression: "dataSearch.status",
                },
              },
              _vm._l(_vm.kidStatusList, function (item) {
                return _c("el-option", {
                  key: item.key,
                  attrs: { value: item.key, label: item.value },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "input-common input-grade",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "Chọn khối" },
                on: {
                  change: function ($event) {
                    return _vm.changeGradeSearch()
                  },
                },
                model: {
                  value: _vm.dataSearch.idGrade,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "idGrade", $$v)
                  },
                  expression: "dataSearch.idGrade",
                },
              },
              _vm._l(_vm.gradeOfSchoolList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { value: item.id, label: item.gradeName },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "input-common input-class",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "Chọn lớp" },
                on: {
                  change: function ($event) {
                    return _vm.searchKidsPackageByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.idClass,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "idClass", $$v)
                  },
                  expression: "dataSearch.idClass",
                },
              },
              _vm._l(_vm.classList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { value: item.id, label: item.className },
                })
              }),
              1
            ),
            _c(
              "el-input",
              {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "Nhập tên học sinh", clearable: "" },
                on: {
                  clear: function ($event) {
                    return _vm.searchKidsPackageByProperties()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchKidsPackageByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.fullName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "fullName", $$v)
                  },
                  expression: "dataSearch.fullName",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.searchKidsPackageByProperties()
                    },
                  },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { float: "right", display: "flex" } }),
        _c(
          "div",
          { staticClass: "table-content row-data" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingData,
                    expression: "loadingData",
                  },
                ],
                ref: "kidsPackageForKidsRef",
                attrs: {
                  data: _vm.responseDataList,
                  "empty-text": _vm.textTable,
                  "element-loading-text": _vm.$tableLoading,
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(255,255,255, 0)",
                  "cell-style": _vm.tableRowStyle,
                  "header-cell-style": _vm.tableHeaderColor,
                  "max-height": _vm.$tableMaxHeight,
                  border: "",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand", width: "1" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { margin: "0 15px 10px 15px" } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingDataChildren,
                                      expression: "loadingDataChildren",
                                    },
                                  ],
                                  ref: "kidsPackageForKidsRef1",
                                  attrs: {
                                    data: scope.row.fnKidsPackageList,
                                    "empty-text": _vm.textTable,
                                    "element-loading-text": _vm.$tableLoading,
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255,255,255, 0)",
                                    "highlight-current-row": "",
                                    "cell-style": _vm.tableRowStyleChildren,
                                    "header-cell-style":
                                      _vm.tableHeaderChidrenColor,
                                    "max-height": _vm.$tableMaxHeightSmall,
                                    border: "",
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      fixed: "",
                                      type: "index",
                                      label: "STT",
                                      width: "50",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      fixed: "",
                                      label: "Tên khoản",
                                      prop: "fnPackage.name",
                                      "min-width": "150",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      fixed: "",
                                      label: "Trạng thái",
                                      align: "center",
                                      width: "95",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.approved
                                                ? _c("span", [
                                                    scope.row.usedNumber == 0 ||
                                                    (scope.row.paid == 0 &&
                                                      scope.row.money > 0)
                                                      ? _c("span", [
                                                          _vm._v("Chưa đóng"),
                                                        ])
                                                      : scope.row.paid <
                                                        scope.row.money
                                                      ? _c("span", [
                                                          _vm._v("Thiếu"),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v("Đủ"),
                                                        ]),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Kiểu đóng",
                                      align: "center",
                                      width: "90",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.fnPackage.type ==
                                              "single"
                                                ? _c("span", [
                                                    _vm._v("Một lần"),
                                                  ])
                                                : scope.row.fnPackage.type ==
                                                  "multiple"
                                                ? _c("span", [
                                                    _vm._v("Nhiều lần"),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Đính kèm",
                                      align: "center",
                                      width: "90",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return scope.row
                                              .fnPackageKidsExtend != null
                                              ? [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          "Khoản đính kèm: " +
                                                          scope.row
                                                            .fnPackageKidsExtend
                                                            .name,
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-circle-check",
                                                        style: scope.row
                                                          .fnPackageKidsExtend
                                                          .active
                                                          ? "margin-left: 10px; color: #409eff"
                                                          : "margin-left: 10px; color: red",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              : undefined
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "Loại",
                                      width: "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.fnPackage.category ==
                                              "in"
                                                ? _c("span", [_vm._v("Thu")])
                                                : scope.row.fnPackage
                                                    .category == "out"
                                                ? _c("span", [_vm._v("Chi")])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "number",
                                      align: "center",
                                      label: "Số lượng",
                                      width: "80",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "right",
                                      label: "Đơn giá",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatCurrencyNew")(
                                                      scope.row.price
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "discount",
                                      align: "center",
                                      label: "Giảm giá",
                                      width: "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.discount
                                                ? _c("span", [_vm._v("Có")])
                                                : _c("span", [_vm._v("Không")]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "discountPrice",
                                      align: "right",
                                      label: "Đơn giá sau giảm",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.discount
                                                ? _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatCurrencyNew"
                                                            )(
                                                              scope.row
                                                                .discountPrice
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ])
                                                : _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatCurrencyNew"
                                                          )(scope.row.price)
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "Điểm danh",
                                      width: "90",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.fnPackage.attendance
                                                ? _c("span", [_vm._v("Có")])
                                                : _c("span", [_vm._v("Không")]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "calculateNumber",
                                      align: "center",
                                      label: "Số dự toán",
                                      width: "90",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Tiền dự toán",
                                      align: "right",
                                      width: "110",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatCurrencyNew")(
                                                      scope.row.moneyTemp
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "usedNumber",
                                      align: "center",
                                      label: "Số sử dụng",
                                      width: "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.usedNumber)
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Tiền phải trả",
                                      align: "right",
                                      width: "130",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatCurrencyNew")(
                                                      scope.row.money
                                                    )
                                                  )
                                                ),
                                              ]),
                                              scope.row.fnPackageKidsExtend !=
                                                null &&
                                              scope.row.fnPackageKidsExtend
                                                .active
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          scope.row
                                                            .moneyExtend > 0
                                                            ? "Số tiền tăng: " +
                                                              scope.row
                                                                .moneyExtend +
                                                              " đ"
                                                            : "Số tiền giảm: " +
                                                              scope.row
                                                                .moneyExtend +
                                                              " đ",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      scope.row.moneyExtend > 0
                                                        ? _c("i", {
                                                            staticClass:
                                                              "el-icon-top",
                                                            staticStyle: {
                                                              color: "#409eff",
                                                              "font-size":
                                                                "18px",
                                                            },
                                                          })
                                                        : scope.row
                                                            .moneyExtend < 0
                                                        ? _c("i", {
                                                            staticClass:
                                                              "el-icon-bottom",
                                                            staticStyle: {
                                                              color: "#f56c6c",
                                                              "font-size":
                                                                "18px",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Tiền đã trả",
                                      align: "right",
                                      width: "110",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatCurrencyNew")(
                                                      scope.row.paid
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "description",
                                      label: "Mô tả",
                                      "min-width": "200",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      fixed: "right",
                                      width: "65",
                                      align: "center",
                                      label: "Duyệt",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  disabled: !scope.row.approved,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.approvedKidsPackageOneMethod(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.approved,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "approved",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.approved",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      fixed: "right",
                                      width: "65",
                                      align: "center",
                                      label: "Khóa",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-checkbox", {
                                                attrs: { disabled: "true" },
                                                model: {
                                                  value: scope.row.locked,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "locked",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.locked",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Tác vụ",
                                      fixed: "right",
                                      width: "100",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteKidPackageRow(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Xóa")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { type: "selection", align: "center", width: "55" },
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "STT",
                    width: "50",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "145",
                    prop: "fullName",
                    label: "Họ tên",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "Ngày sinh",
                    "min-width": "110",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(scope.row.birthDay))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "right",
                    "min-width": "115",
                    label: "Còn lại thu",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "high-light" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  scope.row.totalMoneyRemainIn
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "right",
                    "min-width": "115",
                    label: "Còn lại chi",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "high-light" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  scope.row.totalMoneyRemainOut
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "right",
                    "min-width": "115",
                    label: "Dự toán thu",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  scope.row.totalMoneyIn
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "right",
                    "min-width": "115",
                    label: "Dự toán chi",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  scope.row.totalMoneyOut
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "approvedNumber",
                    width: "80",
                    label: "Đã duyệt",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "lockedNumber",
                    width: "80",
                    label: "Đã khóa",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "Tác vụ", width: "90", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.fnKidsPackageList.length > 0
                            ? _c(
                                "span",
                                [
                                  scope.row.expandRow
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "success",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toogleExpandDetailDate(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-remove-outline",
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toogleExpandDetailDate(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-circle-plus",
                                          }),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("PackageKidsApprovedRapid", {
        ref: "PackageKidsApprovedRapid",
        attrs: { dialogVisible: _vm.showApprovedRapid },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeApprovedRapidDialog()
          },
        },
      }),
      _c("PackageKidsApprovedKidDialog", {
        ref: "PackageKidsApprovedKidDialog",
        attrs: { dialogVisible: _vm.showDetialApprovedDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDetialApprovedDialog()
          },
        },
      }),
      _c("PackageKidsAddDialog", {
        ref: "PackageKidsAddDialog",
        attrs: { dialogVisible: _vm.showAddPackageKids },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseAddPackageKidsMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }